<template>
  <transition name="bounce">
    <ion-card>
      <ion-card-header>
        <div style="margin-bottom: 21px" part="card-header">
          <ion-thumbnail>
            <ion-avatar>
              <img
                :src="outlets['outlet_logo']"
                v-if="outlets['outlet_logo']"
              />
            </ion-avatar>
          </ion-thumbnail>
        </div>
        <ion-card-title>{{ outlets["name"] }}</ion-card-title>
        <p>{{ outlets["address"] }}</p>
      </ion-card-header>
    </ion-card>
  </transition>
</template>

<script>
export default {
  name: "PandaHome",
  props: ["outlets"],
  mounted() {
    console.log(this.outlets);
    setTimeout(() => {
      this.show = true;
    }, 500);
  },
};
</script>

<style scoped>
ion-card-title {
  font-size: 15px;
}
ion-card {
  max-width: 100% !important;
  width: 47%;
  --background: #ef6c00;
  height: 80%;
  box-shadow: none;
  position: fixed;
}
ion-img {
  height: 100px;
  width: auto;
}
p {
  margin: 0;
  color: #fff;
}
/* transition */
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

/* media query */
@media screen and (max-width: 600px) {
  ion-card {
    max-width: 100% !important;
    width: auto;
    --background: #ef6c00;
    box-shadow: none;
    height: auto;
    position: relative;
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
