<template>
  <div>
    <keep-alive>
      <transition-group>
        <!-- Outlet products Category -->
        <ion-card
          v-show="show"
          v-for="(categori, index) in categories"
          :key="index"
        >
          <ion-grid v-if="categori.category_id">
            <ion-row>
              <ion-col
                @click="categori['opened'] = !categori['opened']"
                size="11"
              >
                <transition name="slide-fade">
                  <ion-icon
                    class="icon-bookmark"
                    v-show="categori.has_order"
                    size="higth"
                    color="danger"
                    name="bookmark"
                  >
                  </ion-icon>
                </transition>
                <strong>{{ categori["category_name"] }}</strong>
              </ion-col>
              <ion-col size="1">
                <ion-icon
                  v-if="!categori['opened']"
                  color="danger"
                  :name="arrow"
                >
                </ion-icon>
                <ion-icon
                  v-if="categori['opened']"
                  color="danger"
                  :name="arrow2"
                >
                </ion-icon>
              </ion-col>
            </ion-row>
            <!-- Oultet product -->
            <ion-row class="product-list-wrapper">
              <!-- transition product card -->
              <transition name="slide-fade">
                <ion-col v-show="categori['opened']">
                  <ion-card
                    class="product_card"
                    v-for="(product, produk) in categori['products']"
                    :key="produk"
                  >
                    <ion-row>
                      <ion-col size="4">
                        <ion-img
                          @click="
                            detailsProduct(
                              product['product_id'],
                              product['product_detail_id'],
                              product['name'],
                              product['price_sell'],
                              product['description'],
                              product['photo'],
                              product['stock_status'],
                              product['tax']
                            )
                          "
                          class="productImage"
                          :src="product['photo']"
                          alt="No Image"
                        >
                        </ion-img>
                      </ion-col>
                      <ion-col size="8">
                        <h6
                          @click="
                            detailsProduct(
                              product['product_id'],
                              product['product_detail_id'],
                              product['name'],
                              product['price_sell'],
                              product['description'],
                              product['photo'],
                              product['stock_status'],
                              product['tax']
                            )
                          "
                        >
                          {{ product["name"] }}
                        </h6>
                        <p class="desc" v-if="product['description'] != ''">
                          <!-- <router-link
                            class="link"
                            :to="{
                              name: 'detailProduct',
                              params: {
                                id: $route.params.id,
                                detail_id: product.product_id,
                              },
                            }"
                          >
                            {{ product["description"] }}
                          </router-link> -->
                          {{ product["description"] }}
                        </p>
                        <strong
                          class="price"
                          v-if="product['stock_status'] == 'available'"
                        >
                          {{ formatter.format(product["price_sell"]) }}
                        </strong>
                        <strong
                          v-if="product['stock_status'] == 'unavailable'"
                          class="out"
                          >OUT OF STOCK</strong
                        >
                      </ion-col>
                      <ion-col>
                        <!-- transition add button -->
                        <transition name="bounce">
                          <ion-button
                            v-if="!result(product['product_detail_id'])"
                            :disabled="product['stock_status'] == 'unavailable'"
                            @click="
                              add(
                                product['name'],
                                product['price_sell'],
                                product['product_id'],
                                product['product_detail_id'],
                                product['photo'],
                                '',
                                product['tax']
                              )
                            "
                            class="ion-float-right add-btn"
                            color="danger"
                            clear
                            size="small"
                          >
                            ADD +
                          </ion-button>
                        </transition>
                        <!-- end transition add button -->
                        <!-- transition plus and minus button -->
                        <transition name="slide-fade">
                          <ion-row
                            class="trans ion-float-right"
                            v-if="
                              product['stock_status'] == 'available' &&
                                result(product['product_detail_id']) >= 1
                            "
                          >
                            <ion-button
                              class="ion-float-right order-button"
                              @click="minus(product['name'])"
                              v-if="product['stock_status'] == 'available'"
                              color="danger"
                              size="small"
                            >
                              -
                            </ion-button>

                            <div class="qty-result">
                              {{ result(product["product_detail_id"]) }}
                            </div>

                            <ion-button
                              class="ion-float-right order-button"
                              @click="sum(product['name'])"
                              color="danger"
                              size="small"
                            >
                              +
                            </ion-button>
                          </ion-row>
                        </transition>
                        <!-- end transiton plus and minus button -->
                      </ion-col>
                    </ion-row>
                  </ion-card>
                </ion-col>
              </transition>
              <!-- end transition product card -->
            </ion-row>
          </ion-grid>
          <!-- //* search product card *//  -->
          <ion-card
            v-if="categori.product_id"
            class="product_card  card_result"
          >
            <ion-row>
              <ion-col size="4">
                <ion-img
                  class="productImage"
                  :src="categori['photo']"
                  alt="No Image"
                  @click="
                    detailsProduct(
                      categori['product_id'],
                      categori['product_detail_id'],
                      categori['name'],
                      categori['price_sell'],
                      categori['description'],
                      categori['photo'],
                      categori['stock_status'],
                      categori['tax']
                    )
                  "
                >
                </ion-img>
              </ion-col>
              <ion-col size="8">
                <h6
                  @click="
                    detailsProduct(
                      categori['product_id'],
                      categori['product_detail_id'],
                      categori['name'],
                      categori['price_sell'],
                      categori['description'],
                      categori['photo'],
                      categori['stock_status'],
                      categori['tax']
                    )
                  "
                >
                  {{ categori["name"] }}
                </h6>
                <p class="desc" v-if="categori['description'] != ''">
                  {{ categori["description"] }}
                </p>
                <strong
                  class="price"
                  v-if="categori['stock_status'] == 'available'"
                >
                  {{ formatter.format(categori["price_sell"]) }}
                </strong>
                <strong
                  v-if="categori['stock_status'] == 'unavailable'"
                  class="out"
                  >OUT OF STOCK</strong
                >
              </ion-col>
              <ion-col>
                <transition name="bounce">
                  <ion-button
                    v-if="!result(categori['product_detail_id'])"
                    :disabled="categori['stock_status'] == 'unavailable'"
                    @click="
                      add(
                        categori['name'],
                        categori['price_sell'],
                        categori['product_id'],
                        categori['product_detail_id'],
                        categori['photo'],
                        '',
                        categori['tax']
                      )
                    "
                    class="ion-float-right add-btn"
                    color="danger"
                    clear
                    size="small"
                  >
                    ADD +
                  </ion-button>
                </transition>
                <transition name="slide-fade">
                  <ion-row
                    class="trans ion-float-right"
                    v-if="
                      categori['stock_status'] == 'available' &&
                        result(categori['product_detail_id']) >= 1
                    "
                  >
                    <ion-button
                      class="ion-float-right order-button"
                      @click="minus(categori['name'])"
                      v-if="categori['stock_status'] == 'available'"
                      color="danger"
                      size="small"
                    >
                      -
                    </ion-button>
                    <div class="qty-result">
                      {{ result(categori["product_detail_id"]) }}
                    </div>
                    <ion-button
                      class="ion-float-right order-button"
                      @click="sum(categori['name'])"
                      color="danger"
                      size="small"
                    >
                      +
                    </ion-button>
                  </ion-row>
                </transition>
              </ion-col>
            </ion-row>
          </ion-card>
          <!-- //* end search product card *// -->
        </ion-card>
      </transition-group>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "PandaCategory",
  props: ["categories", "productFormLocalStorage", "show"],
  data() {
    return {
      formatter: new Intl.NumberFormat("ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 2,
      }),
      arrow: "arrow-dropdown",
      arrow2: "arrow-dropright",
      outletProducts: [],
      lastOrder: [],
      product: [],
    };
  },
  mounted() {
    this.product = this.productFormLocalStorage;
    setTimeout(() => {
      this.categories.forEach((v) => {
        v.products.forEach((w) => {
          this.product.forEach((x) => {
            if (w.product_id == x.product_id) {
              v.has_order = true;
            }
          });
        });
      });
    }, 1000);
  },
  updated() {
    // this.categories.forEach((v) => {
    //   v.products.forEach((w) => {
    //     this.product.filter((e) => {
    //       if (e.product_id == w.product_id) {
    //         v.has_order = true;
    //       }
    //     });
    //     // this.product.forEach((x) => {
    //     //   if (w.product_id == x.product_id) {
    //     //     v.has_order = true;
    //     //   }
    //     // });
    //   });
    // });
  },
  methods: {
    //* Add product to cart
    add(name, price, productId, detailid, photo, note = "", tax) {
      let permanenttax = [];
      for (let i = 0; i < tax.length; i++) {
        if (tax[i]["tax_status"] == "permanent") {
          permanenttax.push(tax[i]);
        }
      }

      this.product.push({
        product_name: name,
        price: price,
        product_id: productId,
        product_detail_id: detailid,
        qty: 1,
        photo,
        note,
        tax: permanenttax,
      });
      this.$emit("get-items", this.product);
      localStorage.setItem("last-order", JSON.stringify(this.product));
    },
    //* Add qty product
    sum(name) {
      for (let i = 0; i < this.product.length; i++) {
        if (this.product[i].product_name == name) {
          this.product[i].qty += 1;
        }
      }
      this.$emit("get-items", this.product);
      localStorage.setItem("last-order", JSON.stringify(this.product));
    },
    //* Returning products qty value
    result(detail_id) {
      if (this.product.length) {
        // console.log(this.product);
        for (let i = 0; i < this.product.length; i++) {
          if (this.product[i].product_detail_id == detail_id) {
            return this.product[i].qty;
          }
        }
      }
    },
    //* substract qty products
    minus(name) {
      for (let i = 0; i < this.product.length; i++) {
        if (this.product[i].product_name == name) {
          if (this.product[i].qty) {
            this.product[i].qty -= 1;
            if (this.product[i].qty == 0) {
              this.product.splice(i, 1);
            }
          }
        }
      }
      this.$emit("get-items", this.product);
      localStorage.setItem("last-order", JSON.stringify(this.product));
    },
    showAllProducts() {
      for (let i = 0; i < this.categories.length; i++) {
        if (this.categories[i].products.length) {
          for (let b = 0; b < this.categories[i].products.length; b++) {
            this.outletProducts = this.categories[i].products[b];
          }
        }
      }
    },
    detailsProduct(
      id,
      detailId,
      name,
      price,
      description,
      image = "",
      status,
      tax
    ) {
      let product = {
        id,
        detailId,
        name,
        price,
        description,
        image,
        status,
        tax,
      };
      this.$emit("details-product", product);
    },
    hasOrder(categori) {
      if (categori.has_order == false) {
        categori.has_order = true;
      }
    },
  },
};
</script>

<style scoped>
.product_card {
  margin: 0 0 10px 0;
  background: #fafafa;
}
.card_result {
  margin: 10px;
}
.option {
  margin: 0;
}
.productImage {
  width: 6rem;
  height: 6rem;
}
.out {
  color: crimson;
}
h6 {
  margin-top: 0;
  font-size: 0.9rem;
  color: #222428;
}
.desc {
  font-size: 0.7rem;
}
.price {
  font-size: 0.8rem;
  color: #222428;
}
.btn {
  margin: 0;
}
.trans {
  transition: all 0.2s linear;
}
ion-card {
  box-shadow: none;
  background: #eceff1;
}
ion-row ion-button {
  margin: 0;
  padding: 0;
  --box-shadow: none;
}
ion-row .order-button {
  --border-radius: 50%;
  height: 30px;
  width: 30px;
}
.qty-result {
  width: 25px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #ef6c00;
  margin: 0 0.5rem 0 0.5rem;
  font-weight: bold;
}
ion-col .add-btn {
  height: 1.875rem;
  --border-radius: 30px;
}
.icon-bookmark {
  font-size: 1.5rem;
  position: absolute;
  top: -1rem;
}
.product-list-wrapper {
  transition: max-height 2s ease-in-out;
  max-height: auto;
}
/* transition */
.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
  transition-delay: 0.3s;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-65px);
  opacity: 0;
}
</style>
